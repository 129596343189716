var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"81a39bb4091be1274e83ad5b3c3b7eb83ac0d7de"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://4f42587434554006a3b1f1280428a806@o280737.ingest.sentry.io/6604739',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.8,
  sampleRate: 0.8,
  replaysOnErrorSampleRate: 0.5,
  integrations: [new Sentry.Replay()],
  ignoreErrors: [
    /does not match$/g,
    /Hydration failed/g,
    /hydrating/g,
    'FirebaseError',
    'TransactionInactiveError'
  ],
  autoSessionTracking: true,
  whitelistUrls: ['https://stage.decormatters.com', 'https://decormatters.com'],
  beforeSend(event, hint) {
    if (
      event?.message?.includes('hydrat') ||
      event?.message?.includes(
        'Text content does not match server-rendered HTML'
      ) ||
      hint?.originalException?.message.includes('Minified React error') ||
      hint?.originalException?.message.includes('ydrati') || // stands for Hydration / hydrating /etc
      hint?.originalException?.message.includes('does not match')
    ) {
      return null
    }

    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id })
    }

    return event
  }
})
