import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import locationChangeEvent from '../../utils/locationChangeEvent'
import { FloattingButtonWrapper } from './FloatingButtons.styles'
import { FloatingDailyRewards } from './FloatingDailyRewards'
import { FloatingMenuButton } from './FloatingMenuButton'
import { FloatingScrollButton } from './FloatingScrollButton'

const getElement = () => {
  const topBox = document.getElementById('scroll-box')
  const postsBox = document.getElementById('posts-scroll-box')

  return postsBox || topBox
}

const FloatingButtons = () => {
  const [scrolled, setScrolled] = useState(false)
  const { query, events } = useRouter()
  const [hidden, setHidden] = useState(false)
  const [isBlog, setIsBlog] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    const listener = location => {
      setIsBlog(location.includes('/blog'))

      if (['/create/', '/templates', '/dm', '/challenges', '/event/', '/success/', '/verify', '/leaders'].some(slug => location.includes(slug))) {
        setHidden(true)
      } else {
        setHidden(false)
      }
    }

    listener(window.location.pathname)

    locationChangeEvent.subscribe(listener)

    return () => {
      locationChangeEvent.unsubscribe(listener)
    }
  }, [])

  useEffect(() => {
    const listener = () => {
      const isScrolled = getElement()?.scrollTop > 0

      setScrolled(isScrolled)
    }

    const subscribe = () => {
      getElement()?.addEventListener('scroll', listener)
      listener()
    }

    const unsubscribe = () => {
      getElement()?.removeEventListener('scroll', listener)
    }

    getElement()?.addEventListener('scroll', listener)

    events.on('routeChangeStart', unsubscribe)
    events.on('routeChangeComplete', subscribe)

    return () => {
      unsubscribe()

      events.off('routeChangeStart', unsubscribe)
      events.off('routeChangeComplete', subscribe)
    }
  }, [events])

  if (query[0] && query[0].indexOf('/') !== -1) {
    return null
  }

  const isBlogHidden = isBlog && isMobile

  if (typeof query.inapp !== 'undefined' || hidden) return <FloatingDailyRewards />

  return (
    <>
      <FloattingButtonWrapper>
        {/* {!isBlogHidden && <FloatingQrButton />} */}
        {!isBlogHidden && <FloatingMenuButton />}
        {scrolled && !isBlogHidden ? <FloatingScrollButton /> : null}
      </FloattingButtonWrapper>
      <FloatingDailyRewards />
    </>
  )
}

export { FloatingButtons }
