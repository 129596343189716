import styled from 'styled-components'

export const FloattingButtonWrapper = styled.footer`
  position: fixed;
  top: calc(55vh - 108px);
  right: 32px;
  display: flex;
  flex-direction: column;
  z-index: 9;

  @media (max-width: 786px) {
    right: 5px;

    & > *:not(:nth-child(-n + 2)) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    top: unset;
    bottom: 10px;
    padding-bottom: env(safe-area-inset-bottom);

    & svg {
      width: 55px;
      height: 55px;
    }

    & > div {
      width: 74px;
      height: 60px;
    }
  }
`

export const FloatingButtonIcon = styled.img`
  margin-top: -2px;
`

export const FloatingButtonIconFocused = styled(FloatingButtonIcon)`
  margin-top: -2px;
  display: none;
`

export const ClaimableDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #ff5e6d;
  border-radius: 100%;
  position: absolute;
  top: 14px;
  right: 14px;
  visibility: ${({ taskClaimable }) => (taskClaimable ? 'visible' : 'hidden')};
`

export const Tooltip = styled.div`
  position: absolute;
  display: none;
  right: 80px;
  top: 16px;
  width: 111px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #888888;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    right: -2px;
    top: 16px;
    background: #888888;
    width: 6px;
    height: 10px;
    transform: rotate(45deg);
    z-index: -1;
  }
`

export const FloatingButtonWrapper = styled.button`
  position: relative;
  background: transparent;
  border: none;
  height: 74px;

  ${props =>
    props.hasActiveState &&
    `
      &:hover ${FloatingButtonIcon} {
        display: none;
      }

      &:hover ${FloatingButtonIcon} + ${FloatingButtonIconFocused} {
        display: block;
      }
  `}

  &:hover ${Tooltip} {
    display: block;
  }
`

export const QRTooltip = styled(Tooltip)`
  width: 234px;
  height: 281px;
  border-radius: 10px;
  top: 8px;

  &:after {
    width: 11px;
    height: 19px;
    top: 20px;
    right: -3px;
  }

  @media screen and (max-width: 600px) {
    margin-top: -30vh;
    margin-right: -20px;

    &:after {
      display: none;
    }
  }
`

export const QRTooltipContent = styled.div`
  background: url(${props => props.QRCode});
  background-size: cover;
  width: 194px;
  height: 194px;
  margin-top: 20px;
  margin-left: 20px;
`

export const QRTooltipText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 8px;

  & > span {
    font-size: 18px;
  }
`

export const MenuTooltip = styled(Tooltip)`
  width: 304px;
  height: 190px;
  border-radius: 10px;
  top: 8px;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  &:after {
    display: none;
  }

  @media screen and (max-width: 600px) {
    margin-top: -15vh;
  }
`

export const LongMenuLine = styled.a`
  padding: 0 15px;
  border-radius: 10px;
  font-weight: 700;
  line-height: 45px;
  font-size: 16px;
  display: block;
  margin: 5px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #f5f5f5;
  }
`

export const Separator = styled.hr`
  border: 0.5px solid #e5e5e5;
`

export const LinksGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 16px;
  width: 100%;
  box-sizing: border-box;
`

export const FloatingMenuLink = styled.a`
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #8b8b8b;
  margin-right: 15px;

  &:hover {
    color: #ff5e6d;
  }
`

export const SocialButtons = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 10px;
`

export const DailyRewardsButtonPlacer = styled.div`
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 950;
`

export const DailyRewardsButton = styled.div`
  border-radius: 100px;
  border: 1.5px solid #ff5e6d;
  background: #fff;
  width: 320px;
  height: 66px;
  pointer-events: all;
  margin-bottom: 60px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${props => props.complete && 'filter: grayscale(1);'}

  & svg {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 290px;
    height: 54px;

    & svg {
      width: 38px;
      height: 38px;
    }
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 264px;
    height: 54px;
    font-size: 14px;

    & svg {
      width: 36px;
      height: 36px;
    }
  }

  @media (max-width: 743px) {
    width: 184px;
    height: 44px;
    font-size: 12px;
    margin-bottom: 16px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const RewardsInfo = styled.div`
  width: 213px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9px;
  color: #ff5e6d;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 183px;
    font-size: 14px;
  }

  @media (max-width: 743px) {
    width: 126px;
    font-size: 12px;
    gap: 6px;
  }
`

export const CoinsProgress = styled.div`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 1024px) {
    font-size: 10px;
  }
`

export const ProgressBar = styled.div`
  content: '';
  width: 146px;
  height: 10px;
  background: #eeeeee;
  border-radius: 100px;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 150px;
    height: 8px;
  }

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 120px;
    height: 8px;
  }

  @media (max-width: 743px) {
    width: 80px;
    height: 6px;
  }
`

export const ProgressBarInside = styled.div`
  content: '';
  height: 10px;
  width: ${props => props.percent}%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 100px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
`
