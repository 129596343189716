import styled, { keyframes } from 'styled-components'

export const CommonFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderRoot = styled.div`
  ${props =>
    !props.disableShadow &&
    `
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`}

  @media (min-width: 1024px) {
    display: none;
  }
`

export const HeaderWrapper = styled(CommonFlex)`
  background: rgba(255, 255, 255, 1);
  height: 60px;
  z-index: 900;
  flex-shrink: 0;
`

export const HeaderLeftPart = styled(CommonFlex)``

export const HeaderCommunityMenu = styled(CommonFlex)`
  width: 100%;
  justify-content: center;

  gap: 65px;
`

export const HeaderMenuLink = styled.a`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  font-weight: ${props => (props.active ? '700' : '500')};
  color: #000;
  border-bottom: 4px solid ${props => (props.active ? '#000000' : 'transparent')};
  padding-bottom: 15px;
  padding-top: 10px;
`

export const HeaderRightPart = styled(CommonFlex)`
  margin-right: 15px;
`

export const HeaderButton = styled.button`
  margin-right: 15px;
  background: transparent;
  border: none;
  padding: 0;
  width: 36px;
  height: 36px;
`

export const AvatarImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid transparent;

  ${props =>
    props.active &&
    `
    border: 2px solid #FF5E6D;
  `}
`

const searchAnimation = keyframes`
  0% {
    width: 40px;
  }
  100% {
    width: 100%;
  }
`

export const HeaderSearchWrapper = styled(CommonFlex)`
  background: #f5f5f5;
  border-radius: 93px;
  height: 40px;
  width: 40px;
  margin-right: 14px;
  margin-left: 14px;
  animation: ${searchAnimation} 0.2s ease-in-out forwards;
  padding: 0px 11px;
`

export const SearchIcon = styled.img`
  width: 36px;
  height: 36px;
`

export const SearchInput = styled.input`
  border: none;
  background: transparent;
  width: 100%;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export const SignUpButton = styled.button`
  width: 90px;
  height: 36px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  border: none;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 15px;
    width: 116px;
    height: 38px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  }
`

export const LoginButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  padding-left: 11px;
  padding-right: 11px;
  margin: 0;
  letter-spacing: 0.01em;
  font-weight: 700;
  font-size: 14px;
  color: #ff5e6d;
`

export const DailyRewardsButton = styled.button`
  border: none;
  fill: white;
  border-radius: 32px;
  background: var(--primary-color-gradient, linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%));
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`
