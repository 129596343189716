import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { APPID } from '../../../constants/ApiEndpoints'
import { STRIPE_PRODUCT_IDS } from '../../../constants/StripeConstants'
import { userObject } from '../../../objects/user'
import { getCustomerSubscriptionsByQuery } from '../../../redux/actions/StripeActions'
import { me as savedMe, updatePUser } from '../../../utils/authLocalStorage'
import { getUserToken } from '../../../utils/fetchUtils'
import { goRootStored } from '../utils'
import { DEV_ENDPOINT, PROD_ENDPOINT } from './constants'

const DEV_REVENUS_CAT_AUTH_TOKEN = 'Bearer TmhuQZnVUunBqhidvCeHWJsZKXqJGFaN'
const PROD_REVENUS_CAT_AUTH_TOKEN = 'Bearer strp_GLqDvdpJBHHcIPlVPchqayEWvCD'

//---------------------------------------------------
// MAIN FUNCTIONS TO LOAD USER DATA

export const loadMe = createAsyncThunk('me/loadMe2', async (data, { getState, dispatch }) => {
  try {
    // Get My userdata
    const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT

    if (!getUserToken()) return

    const result = await goRootStored(ep + 'getMe1', null)

    if (result.error) {
      return result
    }

    let usr = userObject(result.result)
    usr['haveMembership'] = false

    //---------------------------------------------------
    // CHECK MEMBERSHIP

    try {
      const savedState = getState()

      const stripeSubscription = await getCustomerSubscriptionsByQuery(usr.objectId || usr.email || savedState?.profile?.me?.username)

      if (stripeSubscription.length > 0) {
        usr.stripeSubscription = stripeSubscription[0]
      }
    } catch (error) {
      // console.log({ stripeLoadError: error })
    }

    const cattoken = APPID === '3' ? PROD_REVENUS_CAT_AUTH_TOKEN : DEV_REVENUS_CAT_AUTH_TOKEN

    const catoptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cattoken
      }
    }

    const url = 'https://api.revenuecat.com/v1/subscribers/' + usr.objectId
    const response = await fetch(url, catoptions)
    const res = await response.json()

    if (!res.subscriber) return usr
    const sub = res.subscriber
    if (Object.keys(sub.entitlements).length === 0 && Object.keys(sub.subscriptions).length === 0) {
      usr.subscriberRecord = null

      return usr
    }

    let membership = null
    let membershipId = null
    let isExpired = true
    let latestExpiration = 0
    const today = new Date()

    //-------------------------------------------------------
    // CHECK STRIPE MEMBERSHIP
    //-------------------------------------------------------

    for (var item in res.subscriber.subscriptions) {
      //check if user own subs match with latest products in stripe
      const m = res.subscriber.subscriptions[item]

      //check sandbox
      if (item === 'yearlyMember' || item === 'monthlyMember') {
        if (m.is_sandbox) {
          usr = {
            ...usr,
            haveMembership: true,
            membership,
            subscriberRecord: res.subscriber
          }
        }
      }

      //regular checks

      const exp = Date.parse(m.expires_date)
      isExpired = exp - today.getTime() <= 0 ? true : false

      if (isExpired === false && exp > latestExpiration) {
        latestExpiration = exp
        const idChecker = STRIPE_PRODUCT_IDS[item]
        membershipId = item

        // if (!idChecker) {
        //   console.error('Unkown Subscription ID')

        //   continue
        // }

        membership = idChecker
        usr = {
          ...usr,
          haveMembership: true,
          membership,
          subscriberRecord: res.subscriber
        }
      }
    }

    //-------------------------------------------------------
    // CHECK APPLE MEMBERSHIP
    //-------------------------------------------------------

    for (var ent in res.subscriber.entitlements) {
      var equivalentName = null
      switch (res.subscriber.entitlements[ent].product_identifier) {
        case 'yearlyMember':
          equivalentName = 'Yearly'
          break
        case 'monthlyMember':
          equivalentName = 'Monthly'
          break
        case 'weeklyMember':
          equivalentName = 'Weekly'
          break
        default:
          equivalentName = null
      }

      if (equivalentName) {
        const m = res.subscriber.entitlements[ent]
        const exp = Date.parse(m.expires_date)
        isExpired = exp - today.getTime() <= 0

        if (isExpired === false) {
          membership = equivalentName
          usr = {
            ...usr,
            haveMembership: true,
            membership
          }
        }
      }
    }

    usr = {
      ...usr,
      membership,
      subscriberRecord: res.subscriber
    }

    if (!membership) return usr

    const stripeProducts = await fetch('/api/payments/products?membershipId=' + membershipId)
    const stripeProductData = await stripeProducts.json()

    const stripePrices = await fetch('/api/payments/prices')
    const stripePriceData = await stripePrices.json()

    const stemp = { ...stripeProductData }
    const desiredPrice = stripePriceData.data?.find(item => item.product === stripeProductData.id)

    if (desiredPrice) {
      stemp.price = desiredPrice
      stemp.nickname = desiredPrice.nickname
    }

    usr.haveMembership = true
    usr.member = true
    usr.membership = {
      ...stemp
    }

    updatePUser(usr)

    return usr
  } catch (e) {
    console.log(e)
  }
})

export const loadOthers = createAsyncThunk('DesignerProfile/loadOthers', async (query, { getState }) => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'getOtherUser1', query)

  if (result.error) return result

  var adjust = Object.assign({}, result.result)
  delete adjust.user

  var pic = 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'
  if (result.result.user.thumbProfileImageFile) pic = result.result.user.thumbProfileImageFile.url
  else if (result.result.user.cfTbImageUrl) pic = result.result.user.cfTbImageUrl

  let usr = {
    ...adjust,
    uniqueDisplayName: 'DM_User_' + result.result.user.objectId.slice(-2),
    pic,
    ...result.result.user
  }

  const cattoken = APPID === '3' ? PROD_REVENUS_CAT_AUTH_TOKEN : DEV_REVENUS_CAT_AUTH_TOKEN

  const catoptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cattoken
    }
  }

  const url = 'https://api.revenuecat.com/v1/subscribers/' + usr.objectId
  const response = await fetch(url, catoptions)
  const res = await response.json()

  if (res.subscriber) usr.subscriberRecord = res.subscriber

  return usr
})

//------------------------------------------------------------

export const saveUsername = createAsyncThunk('DesignerProfile/saveUsername', async uniqueDisplayName => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'updateUserUniqueDisplayName1', {
    uniqueDisplayName
  })
  return result
})

export const saveProfile = createAsyncThunk('DesignerProfile/saveProfile', async (body, { getState }) => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'updateUser1', body)
  return result
})

export const updateEmail = createAsyncThunk('DesignerProfile/updateEmail', async (email, { getState }) => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'updateUser1', { email })
  return result
})

export const setRegSource = createAsyncThunk('DesignerProfile/setRegSource', async (payload, { getState }) => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'updateUser1', { regSource: 'web' })
  return result
})

export const resetPassword = createAsyncThunk('DesignerProfile/resetPassword', async (email, { getState }) => {
  const ep = APPID === '1' ? DEV_ENDPOINT : PROD_ENDPOINT
  const result = await goRootStored(ep + 'requestPasswordReset1', { email })
  return result
})

const profile = createSlice({
  name: 'profile',
  initialState: {
    token: null,
    appid: 3,
    userid: null,
    me: savedMe(),
    pic: null,
    username: null,
    about: null,
    level: null,
    dcoins: null,
    member: null,
    numFollowing: null,
    numFollowers: null,
    numDesigns: null,
    numMyrooms: null,
    numMydecor: null,
    badgeRewards: [],
    badgesNotStarted: [],
    userdata: null,
    others: null,
    loading: true,
    haveMembership: false, //new 2024
    member: false, //new 2024
    membership: {} //new 2024
  },
  reducers: {
    addCoins: (state, action) => {
      state.me.numCoins = state.me.numCoins + action.payload
      state.dcoins = state.me.numCoins
    },
    deductCoins: (state, action) => {
      state.me.numCoins = state.me.numCoins - action.payload
    },
    resetOtherUser: state => {
      state.others = null
      state.loading = true
    },
    setupProfilePic: (state, user) => {
      if (!user) return

      var pic = 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'
      if (user.thumbProfileImageFile) pic = user.thumbProfileImageFile.url
      else if (user.cfTbImageUrl) pic = user.cfTbImageUrl

      state.pic = pic
    },
    setupMe: (state, me) => {
      if (!me) return

      state.level = me.userLevel || 0
      state.dcoins = me.numCoins || 0
      state.badgeRewards = me.badgeRewards || []
      state.badgesNotStarted = me.badgesNotStarted || []
    },
    setupUser: (state, user) => {
      if (!user) return

      state.userid = user.objectId
      state.username = user.uniqueDisplayName
      state.about = user.aboutMe
      state.numFollowing = user.numFollowing
      state.numFollowers = user.numFollowers
      state.numDesigns = user.numDesigns
      state.numMydecor = user.numMyDecor ? user.numMyDecor : state.numMydecor
      state.numMyrooms = user.numUserTemplates ? user.numUserTemplates : state.numMyrooms

      state.userdata = user
      state.me = {
        ...state.me,
        ...user
      }
    },
    refreshUser: (state, action) => {
      if (!action?.payload) return
      const d = action.payload
      if (!d.user) return

      const upcoming = userObject(d)
      const current = _.cloneDeep(state.me)

      const refreshed = {
        ...current,
        ...upcoming
      }

      state.me = refreshed
    },
    updateProfile: (state, action) => {
      state.me = {
        ...state.me,
        ...action.payload
      }
      updatePUser(action.payload)
    },
    init: (state, action) => {
      if (!action.payload) return
      const d = action.payload
      state.token = d.token
      state.appid = d.appid
      state.userid = d.userid
      state.me = d.me

      profile.caseReducers.setupProfilePic(state, d.me)
      profile.caseReducers.setupUser(state, d.me)
    }
  },
  extraReducers: {
    [loadMe.pending]: (state, action) => {
      state.loading = true
    },
    [loadMe.fulfilled]: (state, action) => {
      if (!action.payload) return
      const user = action.payload

      if (user.error) return
      const saved = savedMe()
      state.loading = false
      state.me = {
        ...saved,
        ...user
      }
      state.member = state.me.member || state.me.haveMembership
    },
    [loadOthers.fulfilled]: (state, action) => {
      if (!action.payload) return
      const r = action.payload

      if (r.error) return
      state.others = r
      state.loading = false
    },
    [saveUsername.fulfilled]: (state, action) => {
      if (!action.payload) return

      if (action.payload.error) return

      state.me = {
        ...state.me,
        uniqueDisplayName: action.payload.result.updatedUserObject.uniqueDisplayName
      }
    },
    [saveProfile.fulfilled]: (state, action) => {
      if (!action.payload) return

      if (!action.payload.result) return

      const usr = action.payload.result.user

      const toUpdate = {}

      if (usr.thumbProfileImageFile) {
        toUpdate.thumbProfileImageFile = usr.thumbProfileImageFile
        toUpdate.pic = usr.thumbProfileImageFile.url
      }

      state.me = {
        ...state.me,
        ...usr,
        ...toUpdate
      }
    }
  }
})

export const { init, resetOtherUser, updateProfile, deductCoins, addCoins, refreshUser } = profile.actions

export default profile.reducer
